import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { useMedia } from "use-media";
import { RequestForm } from "../../components/form";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import {
  TestimonialsGoToMobile_November,
  TestimonialsHomePageGoTo_November,
  HrItSupportMobile_November,
  HrItSupport_November,
  Onboarding_November,
  OnboardingMobile_November,
  AdHomePage_November,
} from "../conversational-ai-platform";
import { CaseStudyCtaGoTo } from "./virtual-agent";

const TopImage = require("../../assets/img/home_page_assist/teams_header.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const capabilities_1 = require("../../assets/img/home_page_assist/teams_capabilities_1.png");
const capabilities_2 = require("../../assets/img/home_page_assist/teams_capabilities_2.png");
const capabilities_3 = require("../../assets/img/home_page_assist/teams_capabilities_3.png");
const capabilities_4 = require("../../assets/img/home_page_assist/teams_capabilities_4.png");
const capabilities_5 = require("../../assets/img/home_page_assist/teams_capabilities_5.png");
const capabilities_6 = require("../../assets/img/home_page_assist/teams_capabilities_6.png");
const capabilities_7 = require("../../assets/img/home_page_assist/teams_capabilities_7.png");
const capabilities_8 = require("../../assets/img/home_page_assist/teams_capabilities_8.png");
const capabilities_9 = require("../../assets/img/home_page_assist/teams_capabilities_9.png");
const capabilities_10 = require("../../assets/img/home_page_assist/teams_capabilities_10.png");
const capabilities_11 = require("../../assets/img/home_page_assist/teams_capabilities_11.png");
const capabilities_12 = require("../../assets/img/home_page_assist/teams_capabilities_12.png");
const capabilities_13 = require("../../assets/img/home_page_assist/teams_capabilities_13.png");

const onboard_one = require("../../assets/img/home_page_assist/4x_itsm_onboarding_1.png");
const onboard_two = require("../../assets/img/home_page_assist/4x_itsm_onboarding_2.png");
const onboard_three = require("../../assets/img/home_page_assist/4x_am_onboarding_3.png");
const onboard_four = require("../../assets/img/home_page_assist/4x_itsm_onboarding_4.png");
const onboard_five = require("../../assets/img/home_page_assist/4x_itsm_onboarding_5.png");

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/clients_logo_mob.svg");

export default function LogsPage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free MS Teams Chatbot | MS Teams Bot for Workplace Support"
        description="Use MS Teams Chatbot to automate employee support. Build ai based self-service helpdesk on teams easily in minutes."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
      />
      <section className="support_chatbot_it_help chat_channel_nov_updated">
        <Container additionalClass={"feature-display-none-chat-channel"}>
          <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
            <div className="back_features_hide cc_teams_wrapper chat_channels_page_updated">
              <TopFormWithImage
                image={TopImage}
                image_mob={TopImage}
                altImage="self service microsoft teams bot with workflow automation"
                additionalClassImage={"width-100"}
              >
                <TopFormWithImage.Header>
                  Automate end-user support with Microsoft Teams Bot
                </TopFormWithImage.Header>
                {isSmall ? (
                  <TopFormWithImage.Content>
                    Create teams chatbot, teams integration with apps, teams
                    workflow automation, and agent handover to teams using
                    workativ.
                  </TopFormWithImage.Content>
                ) : (
                  <TopFormWithImage.Content paddingAdd={"padding-160"}>
                    Create teams chatbot, teams integration with apps, teams
                    workflow automation, and agent handover to teams using
                    workativ.
                  </TopFormWithImage.Content>
                )}

                <RequestForm isFooterForm={false} />
              </TopFormWithImage>
            </div>
            <div
              className={`float-left w-100 ${
                isSmall ? "mb-4 mt-4" : "margin-bottom-40"
              }`}
            >
              <div className="container mb-2">
                <h5
                  className={`text-center font-section-normal-text-medium-customer-banner ${
                    isSmall ? "mb-3" : "margin-bottom-20 "
                  }`}
                >
                  Join hundreds of industry leaders
                </h5>{" "}
                {isSmall ? (
                  <img className="" src={bannerImageMobile} />
                ) : (
                  <img className="  d-flex m-auto" src={customerBanner} />
                )}
              </div>
            </div>
            {isSmall ? (
              <HrItSupportMobile_November
                tabContent={tabsForHrIt}
                imagesToPreload={tabsForHrItImages}
                header={"Get your MS Teams bot and automate 10x faster"}
                isComingSoon={tabsForHrIt.isComingSoon}
              />
            ) : (
              <HrItSupport_November
                tabContent={tabsForHrIt}
                imagesToPreload={tabsForHrItImages}
                header={"Get your MS Teams bot and automate 10x faster"}
                headerGroupByCountList={[4, 5, 4]}
                isComingSoon={tabsForHrIt.isComingSoon}
              />
            )}

            {isSmall ? (
              <>
                <OnboardingMobile_November
                  tabContent={tabsForOnBoarding}
                  imagesToPreload={tabsForOnBoardingImages}
                />
              </>
            ) : (
              <Onboarding_November
                tabContent={tabsForOnBoarding}
                imagesToPreload={tabsForOnBoardingImages}
              />
            )}
            <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            <AdHomePage_November />
            <div className="container">
              <div className="usecase_blog_container">
                <h3 class="font-section-sub-header-small-bold-v2 ">
                  Must Read Blogs
                </h3>
                <div className="usecase_bottom_blog">
                  <ul>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/ms-teams-bot-service-desk-transformation"
                      >
                        How MS Teams Bot leads to Service Desk Transformation
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot"
                      >
                        MS Teams Bot - Your ultimate workplace support
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/ms-teams-bot-for-hrms"
                      >
                        The Future of HRMs is Here with MS Teams Bots
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const tabsForOnBoarding = [
  {
    header: "Sign up",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Create a new bot or download pre-built bots from marketplace
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          Create a new bot or download pre-built bots from marketplace
        </p>
        <img src={onboard_one} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Download Pre-built Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Bot Marketplace
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Bot templates are pre-built bots with conversations covering most
            common and repetitive support topics.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Easily download bots in one-click, add new or edit existing support
            topics in minutes.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Marketplace</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Bot templates are pre-built bots with conversations covering most
          common and repetitive support topics.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Easily download bots in one-click, add new or edit existing support
          topics in minutes.
        </p>
        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect App Workflows",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect App Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Connecting chatbot with your apps and building app workflows
            requires a lot of development efforts, time, and costs.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            But workativ provides pre-built app integrations and app workflows
            so you can connect your chatbot with your app and automate tasks
            instantly.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 color-grey mb-0 pb-0">
            No coding.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect App Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connecting chatbot with your apps and building app workflows requires
          a lot of development efforts, time, and costs.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          But workativ provides pre-built app integrations and app workflows so
          you can connect your chatbot with your app and automate tasks
          instantly.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 color-grey mb-0">
          No coding.
        </p>
        <img src={onboard_three} alt="Connect App Workflows"></img>
      </>
    ),
  },
  {
    header: "Test your Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Bot Testing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test your bot in real time using “Try Me”. Make adjustments, add or
            edit conversations, fine tune user interactions, and review your bot
            conversations on the go before you go-live.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test your bot in real time using “Try Me”. Make adjustments, add or
          edit conversations, fine tune user interactions, and review your bot
          conversations on the go before you go-live.
        </p>
        <img src={onboard_four} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Go Live",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Deploy on Teams, Slack, or as Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_five} alt="Go Live"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Deploy on Teams, Slack, or as Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <div className="icons_container_onboarding mb-3">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={onboard_five} alt="Go Live"></img>
      </>
    ),
  },
];

const tabsForOnBoardingImages = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
  onboard_five,
];

const tabsForHrIt = [
  {
    header: "Teams Chatbot",
    hasZeroPaddedDesktopImage: true,
    hasZeroPaddedDesktopImageRight: true,
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Microsoft Teams Bot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Create MS Teams bot easily in minutes using workativ’s no-code
            chatbot builder platform. Workativ helps you quickly build an
            intelligent teams bot with app workflow automation to automate
            workplace support issues directly from teams.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Use workativ to build your own autonomous self-service IT helpdesk
            or HR support in MS Teams and transform your employee workplace
            experience.
          </p>
        </div>
        <div className="support_content_right_container align-self-end pb-0">
          <img src={capabilities_1} alt="Teams Bot"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Microsoft Teams Bot
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Create MS Teams bot easily in minutes using workativ’s no-code chatbot
          builder platform. Workativ helps you quickly build an intelligent
          teams bot with app workflow automation to automate workplace support
          issues directly from teams.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Use workativ to build your own autonomous self-service IT helpdesk or
          HR support in MS Teams and transform your employee workplace
          experience.
        </p>
        <img src={capabilities_1} alt="Teams Bot"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Teams Integrations",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Teams Integration
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Integrate MS Teams Bot easily with apps like servicenow, jira
            service desk, azure, auth0, box, and 100+ apps in few clicks and
            automate tasks, send approvals, notifications or resolve workplace
            issues faster using chatbot with MS Teams integration.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            If you’re on the hunt for software that enables Teams Bot
            integration with 3rd party apps using no-coding, your search ends
            here.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_2} alt="Bot Marketplace"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner ">
          Teams Integration
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Integrate MS Teams Bot easily with apps like servicenow, jira service
          desk, azure, auth0, box, and 100+ apps in few clicks and automate
          tasks, send approvals, notifications or resolve workplace issues
          faster using chatbot with MS Teams integration.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          If you’re on the hunt for software that enables Teams Bot integration
          with 3rd party apps using no-coding, your search ends here.
        </p>
        <img src={capabilities_2} alt="Bot Marketplace"></img>
      </React.Fragment>
    ),
  },

  {
    header: "Teams Workflows",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Microsoft Teams Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Create MS Teams workflows using workativ’s no-code app workflow
            builder.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Add application workflows to teams easily or create custom workflows
            for teams using pre-built actions from marketplace for user
            provisioning, manage user access, payroll, leave management,
            employee onboarding, expense claims, knowledge management and much
            more.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_3} alt="Omnichannel"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Microsoft Teams Workflows
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Create MS Teams workflows using workativ’s no-code app workflow
          builder.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Add application workflows to teams easily or create custom workflows
          for teams using pre-built actions from marketplace for user
          provisioning, manage user access, payroll, leave management, employee
          onboarding, expense claims, knowledge management and much more.
        </p>
        <img src={capabilities_3} alt="Omnichannel"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Teams Onboarding",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            MS Teams Bot Onboarding & Configuration
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Deploy your chatbot on MS Teams with simple steps without any coding
            at all. Apply company branding such as name, description, logo, and
            look and feel to your MS Teams Bot easily in minutes.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_4} alt="Agent Handover"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          MS Teams Bot Onboarding & Configuration
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Deploy your chatbot on MS Teams with simple steps without any coding
          at all. Apply company branding such as name, description, logo, and
          look and feel to your MS Teams Bot easily in minutes.
        </p>
        <img src={capabilities_4} alt="Agent Handover"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Knowledge AI",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Leverage the power of Large Language Model (LLM) and Generative AI
            to super charge your knowledge base and respond to user queries in
            seconds.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_5} alt="knowledge ai"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
        <p className="font-section-normal-text-testimonials  line-height-18 mb-0 pb-0">
          Leverage the power of Large Language Model (LLM) and Generative AI to
          super charge your knowledge base and respond to user queries in
          seconds.
        </p>
        <img
          src={capabilities_5}
          alt="knowledge ai"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },
  {
    header: "Shared Live Inbox",
    hasGridLayoutLargeSmall: true,
    isComingSoon: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">
            Shared Inbox with live chat{" "}
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "24px" }}>
              *
            </sup>
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Engage users with confidence using shared inbox for live chat, bot
            transfers, actions, and much more.
          </p>
          <span className="position-absolute bottom-0 font-section-small-signup-form-medium">
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
              *
            </sup>
            Coming soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_6} alt="shared inbox"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        <h3 className="font-section-normal-text-medium-link">
          Shared Inbox with live chat{" "}
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "16px" }}>
            *
          </sup>
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Engage users with confidence using shared inbox for live chat, bot
          transfers, actions, and much more.
        </p>
        <span className="font-section-small-signup-form-medium">
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
            *
          </sup>
          Coming soon
        </span>

        <img
          src={capabilities_6}
          alt="shared inbox"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },
  {
    header: "Agent Handover",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Live Agent Handover
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ offers seamless{" "}
            <b className="color-black-resource font-section-normal-text-testimonials-medium">
              ‘agent handover’
            </b>{" "}
            of chatbot to live agents with complete context and user
            conversation history so your agents can pick up right where the bot
            left, avoiding any user frustration – but most importantly solving
            end-user issues right away.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Make your MS Teams a live agent channel instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_7} alt="Analytics"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Live Agent Handover
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ offers seamless{" "}
          <b className="color-black-resource font-section-normal-text-testimonials-medium">
            ‘agent handover’
          </b>{" "}
          of chatbot to live agents with complete context and user conversation
          history so your agents can pick up right where the bot left, avoiding
          any user frustration – but most importantly solving end-user issues
          right away.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Make your MS Teams a live agent channel instantly.
        </p>
        <img src={capabilities_7} alt="Analytics"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Approvals",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">
            Approve your chatbot requests in seconds
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            utomating workplace requests can sometimes require manager or
            special approval. Workativ helps you automate and manage approvals
            for employee requests from chatbot easily out-of-the box with
            approval management capability.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Setting up approvals, notification, reminders, expiry, and tracking
            made simple for you.
          </p>
        </div>
        <div className="support_content_right_container align-self-center ">
          <img src={capabilities_8} alt="Notifications"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Approve your chatbot requests in seconds
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          utomating workplace requests can sometimes require manager or special
          approval. Workativ helps you automate and manage approvals for
          employee requests from chatbot easily out-of-the box with approval
          management capability.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Setting up approvals, notification, reminders, expiry, and tracking
          made simple for you.
        </p>
        <img src={capabilities_8} alt="Notifications"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Context Switching",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Context Switching
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            User conversations are never singular. A typical conversation may
            involve more than one context and goal, and even more confusing when
            the user decides to change the request halfway. Workativ handles
            context switching swiftly without loosing users.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_9} alt="context switching"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Context Switching
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          User conversations are never singular. A typical conversation may
          involve more than one context and goal, and even more confusing when
          the user decides to change the request halfway. Workativ handles
          context switching swiftly without loosing users.
        </p>
        <img src={capabilities_9} alt="context switching"></img>
      </>
    ),
  },
  {
    header: "Sensitive Data Handling",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Sensitive Data Handling
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            It gives the ability for the admin to securely handle the user
            information provided to the bot and resolve the user query without
            storing or displaying the user information.{" "}
            <span className="font-section-normal-text-testimonials-medium color-black">
              Example:
            </span>{" "}
            User wants to reset their password and provides the new password to
            the chatbot, and the bot will update the password and will not store
            or display.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_10} alt="sensitive data handling"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Sensitive Data Handling
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          It gives the ability for the admin to securely handle the user
          information provided to the bot and resolve the user query without
          storing or displaying the user information.{" "}
          <span className="font-section-normal-text-testimonials-medium color-black">
            Example:
          </span>{" "}
          User wants to reset their password and provides the new password to
          the chatbot, and the bot will update the password and will not store
          or display.
        </p>
        <img src={capabilities_10} alt="sensitive data handling"></img>
      </>
    ),
  },
  {
    header: "Dynamic Notifications",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Dynamic Notifications
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Proactively notify users on chat to take action at workplace. Be it
            updating their passwords, filling surveys, updating OKRs, personal
            info, or simply surprise them on their birthdays/anniversaries, etc.
            Never let your employees miss a beat.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Bot notifications can be setup using app triggers that can initiate
            the bot to take an action. e.g password expiry, employee onboarding
            etc. Endless possibilities.
          </p>
        </div>
        <div className="support_content_right_container pb-0">
          <img src={capabilities_11} alt="Notifications"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Dynamic Notifications
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Proactively notify users on chat to take action at workplace. Be it
          updating their passwords, filling surveys, updating OKRs, personal
          info, or simply surprise them on their birthdays/anniversaries, etc.
          Never let your employees miss a beat.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Bot notifications can be setup using app triggers that can initiate
          the bot to take an action. e.g password expiry, employee onboarding
          etc. Endless possibilities.
        </p>
        <img src={capabilities_11} alt="Notifications"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Analytics",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Analytics & logs - deep dive on chatbot interactions
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            Great dashboards are useless, said no-one ever. Monitor your chatbot
            performance easily from a single pane of glass.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Review chatbot usage, bot sessions, user queries, live status and
            much more.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_12} alt="Analytics"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Analytics & logs - deep dive on chatbot interactions
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18  pb-1">
          Great dashboards are useless, said no-one ever. Monitor your chatbot
          performance easily from a single pane of glass.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18  pb-0">
          Review chatbot usage, bot sessions, user queries, live status and much
          more.
        </p>
        <img src={capabilities_12} alt="Analytics"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Chat History",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">Chat History</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Using Chat History, you can quickly comb through conversations to
            possibly spot unsuccessful conversation and improve chatbot
            effectiveness. Analyze chatbot and user conversations, user
            experience, and end-user feedback.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_13} alt="chat history"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Chat History</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Using Chat History, you can quickly comb through conversations to
          possibly spot unsuccessful conversation and improve chatbot
          effectiveness. Analyze chatbot and user conversations, user
          experience, and end-user feedback.
        </p>
        <img src={capabilities_13} alt="chat history"></img>
      </>
    ),
  },
];

const tabsForHrItImages = [
  capabilities_1,
  capabilities_2,
  capabilities_4,
  capabilities_5,
  capabilities_6,
  capabilities_7,
  capabilities_8,
  capabilities_9,
  capabilities_10,
  capabilities_11,
  capabilities_12,
  capabilities_13,
];
